@media only screen and (min-width: 768px) {
    .btn-style{
        /* background-color: aqua; */
        margin-left: 3rem;
        width: 300px;
    }
    
    .title-style{
        margin-left: 3rem;
    }
}